<template>
    <div class="rental-order">
        <div class="header pb-8 pt-2 pt-lg-6 d-flex align-items-center">
            
            <div class="container-fluid d-flex align-items-center">
                <div class="col-lg-7 p-0 col-md-10">
                    <h3 class="mb-0 text-white">Objednávka č. {{order.order_id}}</h3>
                    <h2 class="">{{order.first_name}} {{order.last_name}}</h2>

                </div>
            </div>
        </div>
<div class="container-fluid rental-order">
        <div class="card-container mt--8">
            <a-tabs type="card">
                <a-tab-pane key="1" tab="Detail">
                    <a-card shadow type="secondary" class="no-left-top-corner">
                        <template>
                            <div class="info-bars">
                                <a-row :gutter="16">
                                    <a-col :span="18">
                                        <a-card title="Detail objednávky">
                                            <span slot="extra"><i class="fal fa-info-square"></i></span>
                                            <a-list item-layout="horizontal" :data-source="order.products">


                                                <a-list-item slot="renderItem" slot-scope="item, index"  :class="'canceled-' + Array.isArray(item.parsed) + ' index-' + index + ' model-' + item.model + ' ct-' + item.parsed.cttt">

                                                    <a-list-item-meta v-if="!Array.isArray(item.parsed)" :description="
                                                    (item.data.datefrom ?
                                                    formatDateN(item.data.datefrom) + ' - ' + formatDateN(item.data.dateto) +
                                                    (item.parsed.cttt === 'reservation' ?  ' | ' + (item.data.room_name ?  item.data.room_name : order.delivery_first_name + ' ' + order.delivery_last_name) : '') +
                                                    (item.parsed.cttt === 'rental' ?  ' | ' + item.parsed.serial : '') +
                                                    (item.parsed.cttt === 'rental' ? ' | servisný deň: ' + (item.data.serviceday ? 'áno' : 'nie') : '') :
                                                    (item.parsed.description ? item.parsed.description.replace(/<\/?[^>]+(>|$)/g, '') : ''))">
                                                        <a slot="title">{{ item.title }}</a>

                                                        <div slot="avatar">
                                                            <div v-if="item.parsed && item.parsed.image" class="image-place">
                                                                <img v-if="item.parsed.image" :src="item.parsed.image.original">
                                                            <div v-else class="no-image-placeholder"><i class="fal fa-image"></i></div>
                                                            </div>
                                                            <div v-else class="image-place">
                                                                <img v-if="item.data.image" :src="item.data.image.original">
                                                                <div v-else class="no-image-placeholder"><i class="fal fa-image" ></i></div>
                                                            </div>
                                                        </div>
                                                    </a-list-item-meta>
                                                    <a-list-item-meta v-else>
                                                        <a slot="title">{{ item.title }}</a>
                                                        <div slot="avatar">
                                                            <div class="image-place" v-if="item.parsed.image"><img :src="item.parsed.image.original"></div>
                                                            <div v-else class="no-image-placeholder"><i v-if="item.model !== 'delivery'" class="fal fa-image"></i><i class="fal fa-truck" v-else></i></div>
                                                        </div>
                                                    </a-list-item-meta>

                                                    <b>{{Number(item.price).toFixed(2) + ' €'}}</b>
                                                </a-list-item>


                                            </a-list>
                                        </a-card>
                                        <a-card title="Poznámky k objednávke" class="mt-3">
                                            <span slot="extra"><i class="fal fa-sticky-note"></i></span>

                                            <a-timeline>
                                                <a-timeline-item :key="'note-order-' + oind" v-for="(item,oind) in order.notes" :color="item.color">
                                                    <i slot="dot" :class="item.icon" style="font-size: 16px;" v-if="item.icon" />
                                               <div class="note-content">
                                                   <small>{{formatDate(item.created)}}</small>
                                                   <h4>{{item.title}}</h4>  <span v-html="item.message"></span></div>
                                                </a-timeline-item>
                                            </a-timeline>
                                        </a-card>

                                    </a-col>
                                    <a-col :span="6">
                                        <a-card title="Sumár objednávky" class="mb-3 important">
                                            <span slot="extra"><i class="fal fa-calculator-alt"></i></span>
                                            <div class="address-item"><label>Suma v objednávke:</label>{{Number(order.order_total).toFixed(2) + ' €'}}</div>
                                            <div class="address-item"  v-if="order.order_total !== order.finalprice && order.finalprice"><label>Aktuálna suma:</label><b>{{Number(order.finalprice).toFixed(2) + ' €'}}</b></div>
                                            <br><div class="address-item"><label>Vytvorené:</label> {{order.created}}</div>
                                            <div class="address-item"><label>Metóda platby:</label>
                                                <span v-if="order.payment_method === 'cod' || order.payment === ''" >na mieste</span>
                                                <span v-if="order.payment_method === 'payout'">kartou</span>
                                                <span v-if="order.payment_method === 'webpay'">karta</span>
                                                <span v-if="order.payment_method === 'bank'">bankový prevod</span>
                                                <span color="orange"  v-if="order.payment === 'bank'">VS: {{record.vs}}</span></div>
                                            <br>
                                            <div class="address-item" v-if="order.hotel"><label>Objednané zo zariadenia:</label>{{order.hotel}}</div>
                                            <div class="address-item" v-if="order.data.pickuptime"><label>Čas vyzdvihnutia:</label>{{order.data.pickuptime}}</div>
                                            <div class="address-item" v-if="order.data.deliverytime"><label>Čas odovzdania:</label>{{order.data.deliverytime}}</div>
                                        </a-card>

                                        <a-card title="Adresa" class="mb-3">
                                            <span slot="extra"><i class="fal fa-address-card"></i></span>
                                            <div class="address-item">{{order.delivery_first_name}} {{order.delivery_last_name}}</div>
                                            <div class="address-item">{{order.delivery_street1}}</div>
                                            <div class="address-item">{{order.delivery_city}} {{order.delivery_postal_code}}</div><br>
                                            <div class="address-item"><label>Telefón:</label>{{order.delivery_phone}}</div>
                                        </a-card>

                                        <a-card title="Fakturačná adresa" class="mb-3">
                                            <span slot="extra"><i class="fal fa-file-invoice"></i></span>
                                            <div class="address-item"><span v-if="order.data.billing_company">{{order.data.billing_company}}</span><span v-else>{{order.delivery_first_name}} {{order.delivery_last_name}}</span></div>
                                            <div class="address-item">{{order.data.company_contact}}</div><br>
                                            <div class="address-item"><label>IČO:</label> <span v-if="order.data.billing_ico">{{order.data.billing_ico}}</span><span v-else>nie je</span></div>
                                            <div class="address-item"><label>DIČ:</label> <span v-if="order.data.billing_dic">{{order.data.billing_dic}}</span><span v-else>nie je</span></div>
                                            <div class="address-item"><label>IČDPH:</label> <span v-if="order.data.billing_icdph">{{order.data.billing_icdph}}</span><span v-else>nie je</span></div>
                                        </a-card>

                                        <a-card title="Detail zákazníka" class="mb-3">
                                            <span slot="extra"><i class="fal fa-user"></i></span>
                                            <div class="address-item"><label>Číslo zákazníka:</label> {{order.uid}}</div>
                                            <div class="address-item"><label>Email:</label> {{order.primary_email}}</div>
                                        </a-card>
                                    </a-col>


                                </a-row>
                            </div>
                        </template>
                    </a-card>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Upraviť">
                    <div class="row">
                        <div class="col-xl-8 order-xl-1 info-bars">
                            <a-card shadow type="secondary" class="no-left-top-corner">
                                <a-card title="Fakturačné údaje" class="mb-3">
                                    <span slot="extra"><i class="fal fa-file-invoice"></i></span>
                                    <template>
                                    <form @submit.prevent="handleSubmit">
                                        <div class="pl-lg-4">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="Meno"
                                                                input-classes="form-control-compact"
                                                                v-model="order.delivery_first_name"
                                                    />
                                                </div>
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="Priezvisko"
                                                                input-classes="form-control-compact"
                                                                v-model="order.delivery_last_name"
                                                    />
                                                </div>

                                            </div> <div class="row">
                                            <div class="col-lg-6">
                                                <a-input alternative=""
                                                            label="Telefón"
                                                            input-classes="form-control-compact"
                                                            v-model="order.delivery_phone"
                                                />
                                            </div>
                                            <div class="col-lg-6">
                                                <a-input alternative=""
                                                            label="Firma"
                                                            input-classes="form-control-compact"
                                                            v-model="order.billing_company"
                                                />
                                            </div>
                                        </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="Ulica"
                                                                input-classes="form-control-compact"
                                                                v-model="order.delivery_street1"
                                                    />
                                                </div>
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="Ulica 2"
                                                                input-classes="form-control-compact"
                                                                v-model="order.delivery_street2"
                                                    />
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="Mesto"
                                                                input-classes="form-control-compact"
                                                                v-model="order.delivery_city"
                                                    />
                                                </div>
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="Oblasť"
                                                                input-classes="form-control-compact"
                                                                v-model="order.delivery_zone"
                                                    />
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="PSČ"
                                                                input-classes="form-control-compact"
                                                                v-model="order.delivery_postal_code"
                                                    />
                                                </div>
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="Krajina"
                                                                input-classes="form-control-compact"
                                                                v-model="order.delivery_country"
                                                    />
                                                </div>

                                            </div>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <a-input alternative=""
                                                                    label="IČO"
                                                                    input-classes="form-control-compact"
                                                                    v-model="order.data.billing_ico"
                                                        />
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <a-input alternative=""
                                                                    label="DIČ"
                                                                    input-classes="form-control-compact"
                                                                    v-model="order.data.billing_dic"
                                                        />
                                                    </div>
                                                </div>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <a-input alternative=""
                                                                label="IČDPH"
                                                                input-classes="form-control-compact"
                                                                v-model="order.data.billing_icdph"
                                                    />
                                                </div>



                                            </div>    <hr class="my-4"/>  <div class="row">
                                            <div class="col-lg-6">
                                                <a-input alternative=""
                                                            label="Doklad č.1"
                                                            input-classes="form-control-compact"
                                                            v-model="order.data.id"
                                                />
                                            </div>
                                            <div class="col-lg-6">
                                                <a-input alternative=""
                                                            label="Doklad č.2"
                                                            input-classes="form-control-compact"
                                                            v-model="order.data.id2"
                                                />
                                            </div>


                                        </div>  <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group has-label"><label class="form-control-label">Dátum narodenia</label>
                                                    <a-date-picker class="form-control-compact"  style="border:0;"  :default-value="order.data.birthdate ? moment(order.data.birthdate) : ''" v-model="order.data.birthdate"  :locale="locale" />
                                                </div>
                                            </div>


                                        </div> </div>

                                        <hr class="my-4"/>
                                        <!-- Description -->
                                        <div class="pl-lg-4">
                                            <div class="form-group">
                                                <a-input alternative="" label="Poznámka">
                                                 <textarea rows="4" class="form-control form-control-alternative" v-model="order.newnote"></textarea>
                                                </a-input>
                                            </div>
                                        </div>
                                        <div class="text-right">
                                            <button class="btn btn-primary">Uložiť</button>
                                        </div>
                                    </form>
                                </template>
                                </a-card>
                            </a-card>
                            <a-card shadow type="secondary" class="mt-4">
                                <a-card title="Produkty v objednávke" class="mb-3">
                                    <span slot="extra"><i class="fal fa-clipboard-list-check"></i></span>
                                <template>
                                    <a-table
                                            :columns="columns"
                                            :rowKey="record => record.nid"
                                            :pagination="false"
                                            :dataSource="products"
                                            :loading="loading"
                                            class="table-flush white-footer">
                                        <template slot="price" slot-scope="price">
                                            <span v-if="price === 0">Zadarmo</span><span v-else>{{Number(price).toFixed(2) + ' €'}}</span>
                                        </template>
                                        <template slot="title" slot-scope="record">
                                            <h5 style="margin-bottom:0">{{record.title}}</h5>
                                         <span v-if="record.data"><span v-if="record.data.datefrom">{{formatDateN(record.data.datefrom) + ' - ' + formatDateN(record.data.dateto)}}</span></span>
                                             <span v-if="record.parsed" class="description" v-html="record.parsed.description"></span>
                                        </template>
                                        <template slot="serial" slot-scope="record">
                                            <span v-if="record.parsed.serial">{{record.parsed.serial}}</span>
                                            <span v-else>-</span>
                                        </template>
                                        <template slot="image" slot-scope="item">
                                             <span slot="avatar">
                                                            <span v-if="item.parsed && item.parsed.image.fid" class="image-place">
                                                                <img v-if="item.parsed.image.fid"  style="max-height:50px;" :src="item.parsed.image.original">
                                                            <span v-else class="no-image-placeholder" style="width: 60px;"><i class="fal fa-image"></i></span>
                                                            </span>
                                                            <span v-else class="image-place">
                                                                <img  style="max-height:50px;" v-if="item.data.image.fid" :src="item.data.image.original">
                                                                <span v-else class="no-image-placeholder" style="width: 60px;"><i class="fal fa-image" ></i></span>
                                                            </span>
                                                        </span> 
                                        </template>

                                        <template slot="name" slot-scope="user">
                                            <a-button  key="edit" v-if="!user.data.belongsto" size="small" @click="changeProduct(user)"><i class="fas fa-pencil-alt"></i></a-button>
                                            <a-popconfirm placement="topRight"
                                                          @confirm="reservationDelete(user.nid)"
                                                          @cancel="cancel"
                                                          okText="Áno"
                                                          cancelText="Nie">
                                                <template slot="title">
                                                    <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                                    Položka bude trvalo odstránená z objednávky.
                                                </template>
                                                <a-button type="secondary" size="small"><i class="fas fa-trash"></i></a-button>
                                            </a-popconfirm>
                                        </template>
                                        <template slot="footer" class="products-footer" >
                                            <div class="text-right"><a-button type="primary"  @click="openReservationModal"><i class="far fa-plus-square"></i> Pridať</a-button></div>
                                        </template>

                                    </a-table>
                                </template>
                                </a-card>
                            </a-card>
                        </div>
                        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">

                            <div class="card card-profile shadow">
                                <div class="row justify-content-center">
                                    <div class="col-lg-12 order-lg-12">
                                        <div class="text-center pt-5 md-0">   <h3>
                                            Status objednávky<span class="font-weight-light"></span>
                                        </h3>
                                            <div class="h5 font-weight-300">
                                                <a-select  :defaultValue="order.order_status"  v-model="order.order_status" class="w-50 mt-1" :options="statuses"/>
                                            </div>

                                            <a-checkbox v-if="order.order_status === 'payment_recieved'" class="m-3" v-model="order.sendemail">Odoslať potvrdenie o zaplatení emailom</a-checkbox>
                                        </div>

                                    </div>
                                </div>
                                <div class="card-body pt-0 pt-md-4 mt-0">
                                    <div class="row">
                                        <div class="col">
                                            <div class="card-profile-stats d-flex justify-content-center">
                                                <div>
                                                    <span class="heading">{{order.order_total}} €</span>
                                                    <span class="description">Suma</span>
                                                </div>
                                                <div v-if="order.transaction">
                                            <span class="heading"><span v-if="order.transaction.status !== null">
                                                {{order.transaction.amount}} €</span></span>
                                                    <span class="description" v-if="order.transaction.status === 'successful'">Uhradené</span>
                                                    <span class="description" v-else>Neuhradené</span>
                                                </div>
                                                <div v-if="order.order_to_pay">
                                                    <span class="heading" v-if="order.transaction.status === 'successful'"> {{parseFloat(order.order_to_pay) + 0}} € </span>
                                                    <span class="heading" v-else> {{parseFloat(order.order_total) + 0}} € </span>
                                                    <span class="description">Zostáva zaplatiť</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center  mb-5">
                                        <h3>
                                            Spôsob platby<span class="font-weight-light"></span>
                                        </h3>
                                        <div class="h5 font-weight-300">
                                            <i class="ni location_pin mr-2"></i>
                                            <span v-if="order.payment_method === 'bank'">Prevodom na účet</span>
                                            <span v-if="order.payment_method === 'cod'">Na recepcii</span>
                                            <span v-if="order.payment_method === 'payout'">Payout</span>
                                        </div>

                                        <h3>
                                            Posledná zmena<span class="font-weight-light"></span>
                                        </h3>
                                        <div class="h5 font-weight-300">
                                            <i class="ni location_pin mr-2"></i>{{formatDate(order.modified)}}
                                        </div>
                                        <div class="h5 mt-4">
                                            <i class="ni business_briefcase-24 mr-2"></i>Vytvorené
                                        </div>
                                        <div>
                                            <i class="ni education_hat mr-2"></i>{{order.created}}
                                        </div>
                                        <hr class="my-4"/>
                                        <a v-bind:href="'mailto:'+order.email">{{order.email}}</a>
                                    </div>
                                </div>
                            </div>
                            <a-card shadow type="secondary" class="mt-4 info-bars">
                                <a-card title="Spôsob doručenia" class="mb-3">
                                    <span slot="extra"><i class="fal fa-truck-container"></i></span>
                                </a-card>
                            </a-card>
                        </div>
                    </div>
                    <a-modal title="Úprava produktu" centered  v-model="newModalReservation"  @cancel="cancelModal" v-if="newModalReservation">
                        <template  v-if="newExtra">
                            <div  class="new-reservation rental-order">
                                <div class="form-group w-100"><label>Termín: </label>
                                    <a-range-picker class="form-control-compact" v-if="newExtra.datefrom" :default-value="[moment.unix(newExtra.datefrom),moment.unix(newExtra.dateto)]" style="border:0;margin-top:-5px;" v-model="newExtra.daterange"  :locale="locale"  @change="getRental" />
                                    <a-range-picker class="form-control-compact" v-else  style="border:0;margin-top:-5px;" v-model="newExtra.daterange"  :locale="locale"  @change="getRental" />

                            </div>
                                <div class="form-group w-100"  v-if="Object.keys(bikes).length > 0"><label>Bike:</label>
                                    <a-select class="form-control-compact"  style="border:0;" v-model="newExtra.product">

                                        <a-select-opt-group :key="key"  v-for="(model,key) in bikes">
                                            <span slot="label" class="font-weight-bolder" style="font-weight:bold; color:#000;font-size:13px;padding-top:20px;">
                                             <i class="fad fa-biking pr-2" />{{bikeModels[key]}}</span>
                                            <a-select-option :key="bike.value" :value="bike.value" v-for="bike in model">
                                                {{bike.label}}
                                            </a-select-option>
                                        </a-select-opt-group>
                                    </a-select>
                                </div>
                                <div class="form-group w-100"><label>Servisný deň</label>
                                    <span class="form-control-compact alternative-height" style="border:0;"><a-switch v-model="newExtra.serviceday" default-checked style="margin-top:-30px;"/></span></div>
                            <div class="form-group w-100"><label>Rozdielna cena</label>
                                <a-input-number class="form-control-compact" :min="0" :max="10" :step="0.1" v-model="newExtra.price" />
                            </div>
                            <div class="form-group w-100"><label>Doplnky:</label>
                            <a-select
                                      mode="multiple"  class="form-control-compact"
                                      v-model="newExtra.additions"
                                      placeholder="Vyberte si doplnkovú službu">
                                <a-select-option v-for="op in additions" :key="op.value">{{op.label}}</a-select-option
                                >
                            </a-select>
        </div></div>

                            <template slot="footer"  style="padding-top:0;">
                                <a-button  type="white" class="ml-auto" @click="newModalReservation = false; newExtra = {};">Zatvoriť
                                </a-button>
                                <a-button type="primary" @click="addReservation"> Vytvoriť</a-button>

                            </template>
                        </template>
                    </a-modal>

                </a-tab-pane>
                <a-tab-pane key="3" tab="Dokumenty">
                    <div class="row">
                        <div class="col-xl-12 order-xl-1 info-bars">
                            <a-card shadow type="secondary" class="no-left-top-corner">
                                <div class="text-right mt--2 mb-2"  >
                                              <a-button @click="openCampaignModal" type="primary" ><i class="fad fa-layer-plus"></i> Pridať dokument</a-button>
                                </div>

                                <a-modal title="Nový dokument" centered  v-model="campaignModal"  @cancel="campaignModal = false" v-if="campaignModal" @ok="addDocument">
                                    <template>
                                        <a-select  class=" w-100" v-model="newDocument" :options="data"/>

                                    </template>
                                </a-modal>

                                <a-table
                                        :columns="columns_documentation"
                                        :rowKey="record => record.id"
                                        :dataSource="order.rentalDetails.documentation"
                                        :loading="loading"
                                        class="table-flush">


                                    <template slot="date" slot-scope="detailed">
                                        {{formatDateP(detailed)}}
                                    </template>
<template slot="pdf" slot-scope="pdf">
    <a target="_blank" :href="pdf"> <a-button   type="primary" ><i class="fas fa-file-pdf pr-1"></i> otvor dokument</a-button></a></template>
                                    <template slot="name" slot-scope="user">
                                        <router-link :to="'/edit-document/' + user.id" class="btn text-default btn-icon btn-secondary btn-sm" :href="'/edit-document/' + user.id"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Upraviť</router-link>
                                        <a-popconfirm placement="topRight"
                                                      @confirm="confirm(user.id)"
                                                      @cancel="cancel"
                                                      okText="Áno"
                                                      cancelText="Nie">
                                            <template slot="title">
                                                <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                                Položka bude trvalo odstránená z vašej stránky.
                                            </template>
                                            <a-button type="secondary"  icon="fas fa-trash"    textColor="default" title="Zmazať">Zmazať</a-button>
                                        </a-popconfirm>
                                    </template>
                                </a-table>

                            </a-card>
                        </div>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
</div>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import {Modal} from 'ant-design-vue'
    import FormItem from 'ant-design-vue'
    import {Cropper} from 'vue-advanced-cropper'
    import moment from 'moment'

    import locale from 'ant-design-vue/es/date-picker/locale/sk_SK';
    Vue.use(FormItem, Modal);
    Vue.use(Cropper);
  

    export default {
        name: 'order',
        props: ['id'],

        data() {
            return {
                loading: false,
                visible: false,
                originInfo: '',
                imageUrl: '',
                newDocument:false,
                fileList: [],
                data: false,
                locale,
                moment: moment,
                bikes: {},
                roleOptions: [],
                imgCroppFile: '',
                statuses:[
                    {"value":"pending","label":"Čaká sa"},
                    {"value":"payment_recieved","label":"Platba prijatá"},
                    {"value":"storno","label":"Storno"},
                    {"value":"abandoned","label":"Opustená"},
                    {"value":"completed","label":"Kompletná"},
                ],
                uploading: false,
                croppedImage: '',
                bikeModels:[],
                additions:[],
                imageUploaded: '',
                newExtra: {'serviceday':true,product: ''},
                columns : [
                    {
                        title: '',
                        scopedSlots: {customRender: 'image'}
                    },
                    {
                        title: 'Produkt',
                        scopedSlots: {customRender: 'title'}
                    },
                    {
                        title: 'Seriové číslo',
                        scopedSlots: {customRender: 'serial'}
                    },
                    {
                        title: 'Cena',
                        dataIndex: 'price',
                        scopedSlots: {customRender: 'price'}
                    },
                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                        width: '140px'
                    }
                ],
                columns_documentation : [
                    {
                        title: 'Názov',
                        dataIndex: 'title',
                    },
                    {
                        title: 'Vytvorené',
                        dataIndex: 'created',
                        scopedSlots: {customRender: 'date'}
                    },
                    {
                        title: 'PDF',
                        dataIndex: 'pdf',
                        scopedSlots: {customRender: 'pdf'}
                    },
                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                        width: '440px'
                    }
                ],
                roles: '',
                campaignModal:false,
                products: [],
                head: authHeader(),
                colorsto: JSON.parse(localStorage.colors),
                send: API_URL,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 14},
                },
                orderId: {
                    uid: this.id
                },
                order: {
                    first_name: '',
                    last_name: '',
                    image: this.imageUploaded,
                },
                newModalReservation: false,
                model: {
                    ordername: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    address: '',
                    city: '',
                    country: '',
                    zipCode: '',
                    about: '',
                }
            }
        },
        methods: {
            addDocument()
            {

                dataService.axiosPost("newdocument", {order_id: this.id,type: this.newDocument}).then(() => {
                    this.newDocument = false;
                    this.campaignModal = false;
                    this.fetch();
                });
            }, downloadWithVueResource(url) {
console.log(url);
                this.$http({
                    method: 'get',
                    url: url,
                    responseType: 'arraybuffer'
                })
                    .then(response => {
                        this.forceFileDownload(response)
                    })
                    .catch(() => console.log('error occured'))

            },
            forceFileDownload(response){
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'file.png') //or any other extension
                document.body.appendChild(link)
                link.click()
            },
            downloadItem ({ url, label }) {
                Axios.get(url, { responseType: 'blob' })
                    .then(response => {
                        const blob = new Blob([response.data], { type: 'application/pdf' })
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = label
                        link.click()
                        URL.revokeObjectURL(link.href)
                    }).catch(console.error)
            },
            showModal() {
                this.visible = true;
            },
            openCampaignModal() {
                this.campaignModal = true;
            },
            formatDate: function (dt) {
                return moment.unix(dt).format('D.M.YYYY H:mm')

            },
            formatDateP: function (dt) {
                return moment(dt).format('D.M.YYYY H:mm')

            },
            formatDateN: function (dt) {
                return moment.unix(dt).format('D.M.YYYY')

            },
            changeProduct(product)
            {
                console.log(product);
                this.newExtra = product.data;

                this.newExtra.id = product.nid;
                this.newModalReservation = true;
            },
            change({coordinates, canvas}) {
                this.croppedImage = canvas.toDataURL();
                this.imgCroppFile = canvas.toDataURL("image/jpeg");

            },
            confirm(e) {
                dataService.axiosDelete("document", e).then(results => {
                    if (results) {
                        this.order.rentalDetails.documentation.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            reservationDelete(r) {
                dataService.axiosDelete('rental', r).then(results => {
                    if (results) {
                        this.newModalReservation = false;
                        this.fetch();
                    }
                });
            },
            cancelModal()
            {
                
                this.newModalReservation = false;
                this.bikes = {};
                this.newExtra = {};
            },
            addReservation()
            {
                if(this.newExtra.product)
                {
                    this.newExtra.order_id = this.id;
                    dataService.axiosPost("arent", this.newExtra).then(() => {
                        this.newExtra = {};
                        this.newModalReservation = false;
                        this.fetch();
                    });
                }
                else {
                    return false
                }
            },
            cancel()
            {
                this.newModalReservation = false;
            },
            backEndDateFormat(date) {
                return moment(date._d).format('YYYY-MM-DD');
            },
            getRental: function (rese) {

                var from = this.backEndDateFormat(rese[0]);
                var to = this.backEndDateFormat(rese[1]);

                //  let daterange = [moment(rese.datefrom, this.rdateFormat), moment(rese.dateto, this.rdateFormat)];


                dataService.axiosFetch("getrental/" + from + "/" + to).then(results => {
                    this.bikes = results;
                });
            },
            handleChange(info) {

            },
            openReservationModal()
            {
                this.chooseTimes = {};
                this.newModalReservation = true;
            },
            sayshit(e) {
                console.log(e);
            },
            handleSubmit() {
                let $order_data = this.order;

                dataService.axiosPost(this.$options.name, $order_data).then(results => {
                    if (results.data.success) {
                        this.$message.success('Obsah bol upravený');
                        this.fetch();
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
            fetch()
            {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.order = results;
  var pa = this;
pa.products = [];
                    results.products.forEach(obj => {
                        if(!obj.serial)
                        obj.serial = "-";
             if(obj.data.title)
                 pa.products.push(obj);
                    });

                });

            },
            normFile(e) {
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;


            },
        },
        mounted() {
            if (!isNaN(this.id)) {

             this.fetch();
                dataService.axiosFetch("content/addition").then(results => {
                    this.additions = dataService.getCheckboxOptions(results);

                });
                dataService.axiosFetch('documents').then(results => {
                    var roleops = [];
                    results.forEach(obj => { roleops.push({"value" : obj.id , "label" : obj.title}); });

                    this.data = roleops;
                });

var pa = this;
                dataService.axiosFetch("bikes").then(results => {

                    results.rooms.forEach(obj => {
                        pa.bikeModels[parseInt(obj.id)] = obj.title;
                    });


                });


            }
        }
    };
</script>
<style>
.card-container .important label
{margin-bottom:5px;}
.input-group .input-group-prepend .input-group-text {
border: 0;
}

.note-content
{display:inline-block;
margin-left:10px;
width:100%;}
.note-content small
{float:right;}
.ant-upload.ant-upload-select-picture-card
{
background-color: transparent;
border:0;}
.avatar-uploader
{z-index: 9999;
position: relative;
display: block;
border-radius: 100%;
overflow: hidden;
top: -12px;}
.ant-upload.ant-upload-select-picture-card > .ant-upload
{color:#fff;}
.card-container {
padding: 24px;
}
.description p{
    font-size: 0.875rem;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
margin-top: -16px;
}
.no-left-top-corner
{border-top-left-radius: 0}

.card-container > .ant-tabs-card > .ant-tabs-bar {
border: 0;
margin-bottom:12px;
}

    .new-reservation .form-group .ant-input-number-input
    {height:30px!important}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
font-weight:bold;
background: #f2f4f9;
}
.card-container .info-bars .ant-card-body
{min-height:150px;}
.info-bars .ant-card-extra i
{opacity:.3;font-size:20px;}
.address-item label{
font-weight:bold;
margin:0;
margin-right:5px;

}

.rental-order .form-group.has-label
{margin:0;}
.rental-order .form-group.has-label .form-control-label
{
    margin:0;
    padding-top:12px;
    line-height:30px;
    padding-bottom:12px;
}
.rental-order .form-control-compact {
    float: right;
    width: 60%;
    margin-right: 5%;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    border-top-width: 1.02px;
    overflow: visible;
}
.alternative-height
{
    line-height:45px;

}
.info-bars .ant-list-item img
{max-width:100px;}
.info-bars .ant-list-item
{padding-right:20px;
border-bottom:0!important;}
.ct-rental .image-place
{width:100px}
.ct-rental h4
{font-size:14px;width:100%;display:inline-block;}
.ct-rental img
{max-height:80px;}
.ct-rental:not(.index-0)
{border-top: 1px solid #ebebeb;margin-top:20px;padding-top:30px;}

.ct-rentaddition
{border-top: 1px solid #f5f5f5;}
.ct-rental h4,.model-delivery h4
{color:#333}

.model-delivery
{min-height:100px;margin-top:20px;
    margin-bottom:13px;
border-top: 1px solid #ebebeb; }
.ct-rentaddition img
{width:80px;margin-right:20px;}
.no-image-placeholder
{width:100px; text-align:center;
color:#000;
}
.no-image-placeholder i
{font-size:30px; padding:0;}
.canceled-true:not(.model-delivery)
{border-top: 1px solid #ebebeb;
}
.canceled-true:not(.model-delivery) .no-image-placeholder i{
    padding-top:3px;
    padding-bottom:3px;
    opacity:.1;

}
.card-container .ant-card-extra,.card-container .ant-card-head-title
{padding:10px 0;}
.card-container .ant-card-head {
min-height: unset;
}
.white-footer .ant-table-footer{
    background-color:#fff;
    border-top:2px solid #e8e8e8
}
.font-size-30
{font-size:30px;}
.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
border-color: #fff;
background: #f7fafc;
z-index: 9;
}
.white-footer .ant-table-title
{display:none;}
    .canceled-true:not(.model-delivery)
    {
        text-decoration: line-through;
    opacity:.6}
 
</style>
